<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="is-pulled-right">
            <b-field grouped>
              <b-switch v-model="params.archived">
                Arhiveeritud
              </b-switch>
              <b-button type="is-primary"
                icon-left="download"
                :disabled="disabled"
                :loading="loading"
                @click="load()">
                Jooksuta
              </b-button>
            </b-field>
          </div>
          <h1 class="title">
            Puuduvad aarded
          </h1>
          <h2 class="subtitle">
            GP / GC Eesti aarete erinevused
          </h2>
        </div>
      </div>
    </section>
    <section v-if="Object.keys(caches).length === 2">
      <div class="container">
        <section class="modal-card-body">
          <div class="columns">
            <div v-for="key in Object.keys(caches)" class="column is-half" :key="key">
              <h3 class="subtitle mt-5">
                {{ key }} puuduvad aarded ({{ caches[key].length }})
              </h3>
              <b-table sortable
                :data="caches[key]"
                :bordered="true"
                :striped="true"
                :narrowed="true"
                :hoverable="true"
                default-sort="name"
                :mobile-cards="true">
                <template v-for="col in columns">
                  <b-table-column :key="col.field" v-bind="col" cell-class="cell">
                    <template v-slot="props">
                      <common-list-cell
                        :col="col"
                        :row="props.row">
                      </common-list-cell>
                    </template>
                  </b-table-column>
                </template>
              </b-table>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'
import CommonListCell from '@/components/CommonListCell.vue'

export default {
  name: 'MissingList',
  components: {
    CommonListCell
  },
  data() {
    let data = JSON.parse(localStorage.getItem('user'))
    const providers = []
    if (data && data['gp']) {
      providers.push('gp')
    }
    if (data && data['gc']) {
      providers.push('gc')
    }
    return {
      providers: providers,
      disabled: providers.length === 0,
      loading: false,
      caches: {},
      columns: [
        {
          field: 'code',
          label: 'Kood',
          sortable: true,
          width: 60
        },
        {
          field: 'name',
          addon: ['status'],
          label: 'Aare',
          sortable: true
        },
        {
          field: 'time',
          label: 'Peidetud',
          sortable: true,
          width: 115
        },
        {
          field: 'premium',
          label: 'Premium',
          sortable: true,
          width: 60
        }
      ],
      params: {
        active: true,
        disabled: true,
        archived: false
      }
    }
  },
  methods: {
    load () {
      this.disabled = true
      this.loading = true

      apiService.loadCachesMissingList(this.params)
        .then(response => {
          if (response.ok) {
            const list = {}
            Object.keys(response.data.compare).forEach(provider => {
              Object.keys(response.data.compare[provider].to).forEach(to => {
                if (!(provider in list)) {
                  list[provider] = response.data.compare[provider].to[to].missed
                }
              })
            })
            this.caches = list
          }
        })
        .catch(error)
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    }
  }
}
</script>
